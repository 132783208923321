export const Colors = {
    primary: "#82b482",
    secondary: "#69b4c8",
    danger: "#FB5607",
    attention: "#FFBE0B",
    okay: "#28B6A6",
    primaryShade1: "#919b9b",
    primaryShade2: "#465555",
    replacement: "#052F5F",
    background: "#F9F9F9",
    released: "#29FF00",
};

